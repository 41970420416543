@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --border-opacity: 1;
}

@layer components {
    .skeleton-loader {
        height: 15px;
        display: block;
        background: linear-gradient(	  
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ), lightgray;
        background-repeat: repeat-y;
        background-size: 50px 500px;
        background-position: 0 0;
        animation: shine 1s infinite;	  
        z-index: 99;
    }

    .shadow-brand-green-dark { 
        --tw-shadow-color: #181A16
    }

    @keyframes shine  {
       
    }
}